import { Container, Segment, Button, ButtonContent, Icon } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import { useSelector, shallowEqual } from 'react-redux';

import {
  Anontools,
  LanguageSelector,
  Logo,
  Navigation,
  SearchWidget,
} from '@plone/volto/components';

const Header = ({ pathname }) => {
  const token = useSelector((state) => state.userSession.token, shallowEqual);

  return (
    <Segment basic className="header-wrapper" role="banner">
      <Container>
          <div className="header">
            <div className="logo-nav-wrapper">
              <div className="logo">
                <Logo />
              </div>
              {/*<Navigation pathname={pathname} />*/}
            </div>
            <div className="subbtn print_hide">
              <Button color='red' animated href="https://cra-sa.com/schedule-a-call" target="_blank">
                <ButtonContent visible>Subscribe to the Risk Alert</ButtonContent>
                <ButtonContent hidden>Talk to us <i class="bi bi-arrow-right-circle-fill"></i></ButtonContent>
              </Button>
            </div>
            <div className="tools-search-wrapper">
              <LanguageSelector />
              {!token && (
                <div className="tools">
                  <Anontools />
                </div>
              )}
              <div className="search">
                <SearchWidget />
              </div>
            </div>
          </div>
      </Container>
    </Segment>
  );
};

export default Header;

Header.propTypes = {
  token: PropTypes.string,
  pathname: PropTypes.string.isRequired,
  content: PropTypes.objectOf(PropTypes.any),
};

Header.defaultProps = {
  token: null,
  content: null,
};
