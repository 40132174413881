/**
 * Footer component.
 * @module components/theme/Footer/Footer
 */

import React from 'react';
import { Container, List, Segment, Grid, GridRow, GridColumn, Icon, Button, ButtonContent } from 'semantic-ui-react';
import { map } from 'lodash';
import { FormattedMessage, defineMessages, injectIntl } from 'react-intl';
import { useSelector, shallowEqual } from 'react-redux';
import { UniversalLink } from '@plone/volto/components';
import { flattenToAppURL, addAppURL } from '@plone/volto/helpers';

const messages = defineMessages({
  copyright: {
    id: 'Copyright',
    defaultMessage: 'Copyright',
  },
});

/**
 * Component to display the footer.
 * @function Footer
 * @param {Object} intl Intl object
 * @returns {string} Markup of the component
 */
const Footer = ({ intl }) => {
  const { siteActions = [] } = useSelector(
    (state) => ({
      siteActions: state.actions?.actions?.site_actions,
    }),
    shallowEqual,
  );

  return (
    <div class="footer-container print_hide">
      <Container>
        <Segment textAlign='center' id="subscribe-footer">
          <h2>Subscribe to the Risk Alert</h2>
          <p>CRA subscribers gain access to a range of risk reports, data and research support services, as well as regular seminars and customised briefings. This includes Seminars, Weekly WhatsApp Alerts, Bespoke Briefings, Research Support, and all CRA Publications.</p>
          <Button color='red' animated href="https://cra-sa.com/schedule-a-call" target="_blank">
            <ButtonContent visible>Subscribe to the Risk Alert</ButtonContent>
            <ButtonContent hidden>Talk to us <i class="bi bi-arrow-right-circle-fill"></i></ButtonContent>
          </Button>
        </Segment>
      </Container>
      <Segment
        role="contentinfo"
        vertical
        padded
        inverted
        id="footer"
        aria-label="Footer"
      >
        <Container>
          <div id="footer-main">
            <Grid columns={6}>
              <GridColumn textAlign='left' mobile={16} tablet={8} computer={2}>
                <List>
                  <List.Header>
                    <header as="h3">Quick Links</header>
                  </List.Header>
                  <List.Content>
                    <List.Item>
                      <UniversalLink href="https://cra-sa.com/about" target="_blank">About the CRA</UniversalLink>
                    </List.Item>
                    <List.Item>
                      <UniversalLink href="https://cra-sa.com/subscribe" target="_blank">Talk to us about subscribing</UniversalLink>
                    </List.Item>
                    <List.Item>
                      <UniversalLink href="https://cra-sa.com/services" target="_blank">Services</UniversalLink>
                    </List.Item>
                    <List.Item>
                      <UniversalLink href="https://cra-sa.com/media" target="_blank">Media</UniversalLink>
                    </List.Item>
                    <List.Item>
                      <UniversalLink href="https://cra-sa.com/videos" target="_blank">Videos</UniversalLink>
                    </List.Item>
                  </List.Content>
                </List>
              </GridColumn>
              <GridColumn textAlign='left' mobile={16} tablet={8} computer={2}>
                <List>
                  <List.Header>
                    <header as="h3">Products</header>
                  </List.Header>
                  <List.Content>
                    <List.Item>
                      <UniversalLink href="https://cra-sa.com/products/socio-economic-survey" target="_blank">Survey</UniversalLink>
                    </List.Item>
                    <List.Item>
                      <UniversalLink href="https://cra-sa.com/products/fast-stats" target="_blank">Fast Stats</UniversalLink>
                    </List.Item>
                    <List.Item>
                      <UniversalLink href="https://cra-sa.com/products/macro-review" target="_blank">Macro Review</UniversalLink>
                    </List.Item>
                    <List.Item>
                      <UniversalLink href="https://cra-sa.com/products/risk-alert" target="_blank">Risk Alert</UniversalLink>
                    </List.Item>
                    <List.Item>
                      <UniversalLink href="https://cra-sa.com/products/client-note" target="_blank">Client Note</UniversalLink>
                    </List.Item>
                  </List.Content>
                </List>
              </GridColumn>
              <GridColumn textAlign='left' mobile={16} tablet={8} computer={2}>
                <List>
                    <List.Header>
                      <header as="h3">Contact us</header>
                    </List.Header>
                    <List.Content>
                      <List.Item>
                        <UniversalLink href="https://cra-sa.com/schedule-a-call" target="_blank">Schedule a call</UniversalLink>
                      </List.Item>
                      <List.Item>
                        <UniversalLink href="#https://cra-sa.com/contact-us" target="_blank">Contact us</UniversalLink>
                      </List.Item>
                      <List.Item>
                        <UniversalLink href="https://cra-sa.com/privacy-policy" target="_blank">Privacy policy</UniversalLink>
                      </List.Item>
                      <List.Item>
                        <UniversalLink href="#https://cra-sa.com/terms-conditions" target="_blank">Terms & conditions</UniversalLink>
                        <hr/>
                      </List.Item>
                      <List.Item>
                        <List horizontal>
                          <List.Item><UniversalLink href="https://www.linkedin.com/company/centre-for-risk-analysis" target="_blank"><i class="bi bi-linkedin"></i></UniversalLink></List.Item>
                          <List.Item><UniversalLink href="https://twitter.com/centre_risk" target="_blank"><i class="bi bi-twitter-x"></i></UniversalLink></List.Item>
                          <List.Item><UniversalLink href="https://www.facebook.com/CentreForRiskAnalysis/" target="_blank"><i class="bi bi-facebook"></i></UniversalLink></List.Item>
                          <List.Item><UniversalLink href="https://www.youtube.com/channel/UCHRcn9vEaeKapV_EkfwFQJQ" target="_blank"><i class="bi bi-youtube"></i></UniversalLink></List.Item>
                        </List>
                      </List.Item>
                    </List.Content>
                  </List>
              </GridColumn>
              <GridColumn mobile={16} tablet={8} computer={2}></GridColumn>
              <GridColumn mobile={16} tablet={8} computer={2}></GridColumn>
              <GridColumn textAlign='right' verticalAlign='bottom' mobile={16} tablet={8} computer={2}>
                <List>
                  <List.Content>
                    <List.Item>
                      <FormattedMessage
                        id="{copyright} Centre for Risk Analysis {current_year}"
                        defaultMessage="{copyright} Centre for Risk Analysis {current_year}"
                        values={{
                          copyright: (
                            <abbr title={intl.formatMessage(messages.copyright)}>©</abbr>
                          ),
                          current_year: new Date().getFullYear(),
                        }}
                      />
                    </List.Item>
                    <List.Item>
                      <UniversalLink href="#top" class="header-link"><i class="bi bi-arrow-up-circle-fill"></i></UniversalLink>
                    </List.Item>
                  </List.Content>
                </List>
              </GridColumn>
            </Grid>
          </div>
        </Container>
      </Segment>
      <div>
        <Container textAlign="center">
          <div class="below-footer">
            CMS Website by <UniversalLink href="https://juizi.com" target="_blank">Juizi</UniversalLink>
          </div>
        </Container>
      </div>
    </div>
  );
};

/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
Footer.propTypes = {
  /**
   * i18n object
   */
};

export default injectIntl(Footer);
